
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Card from "primevue/card";

import LogsDataTable from "@/components/UI/LogsDataTable.vue";

export default defineComponent({
  name: "Logs",
  components: {
    Card,
    LogsDataTable,
  },
  computed: {
    ...mapGetters({
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
    }),
    associatedTypes(): Array<{ assoc_file: string; assoc_id: string }> {
      return [
        { assoc_file: "SO", assoc_id: this.currentId },
        { assoc_file: "Customer", assoc_id: this.cust_id },
      ];
    },
  },
  data: () => ({
    currentId: "",
    cust_id: "",
    isLoadingLogEntry: false,
  }),

  async mounted() {
    if (!this.$attrs.orderId) {
      this.$router.push("/sales/orders");
    }
    let id = this.$attrs.orderId as string;
    this.cust_id = this.getActiveOrderTab().sold_to || "";
    this.currentId = id;
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogsDataTable = _resolveComponent("LogsDataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl pl-2 tab-card-content" }, {
      content: _withCtx(() => [
        _createVNode(_component_LogsDataTable, {
          elementId: _ctx.currentId,
          file: "SO",
          associatedTypes: _ctx.associatedTypes
        }, null, 8, ["elementId", "associatedTypes"])
      ]),
      _: 1
    })
  ]))
}